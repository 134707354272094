import React from 'react';
import './whatsAppButton.css';

const WhatsAppButton = () => {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const whatsappMessage = process.env.REACT_APP_WHATSAPP_MESSAGE;
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <img src="https://www.svgrepo.com/show/176768/whatsapp-social-media.svg" alt="WhatsApp" className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;