// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { PlasmicComponent } from '@plasmicapp/loader-react';
// import './header.css';

// const Header = () => {
//   const [show, setShow] = useState(true);
//   const [lastScrollY, setLastScrollY] = useState(0);

//   const controlNavbar = () => {
//     if (typeof window !== 'undefined') { 
//       if (window.scrollY > lastScrollY) {
//         setShow(false); 
//       } else {
//         setShow(true);  
//       }
//       setLastScrollY(window.scrollY); 
//     }
//   };

//   useEffect(() => {
//     if (typeof window !== 'undefined') {
//       window.addEventListener('scroll', controlNavbar);
//       return () => {
//         window.removeEventListener('scroll', controlNavbar);
//       };
//     }
//   }, [lastScrollY]);

//   return (
//     <div>
//       {/* <PlasmicComponent component="Header" /> */}
//       <nav className={`navbar ${show ? '' : 'hidden'}`}>
//         <div className="logo" style={{backgroundImage: `url('/logoAT.png')`}}></div>
//         <ul className="nav-list">
//           <li className="nav-item"><Link className="nav-link" to="/">Inicio</Link></li>
//           <li className="nav-item"><Link className="nav-link" to="/about">Quiénes Somos</Link></li>
//           <li className="nav-item"><Link className="nav-link" to="/services">Servicios</Link></li>
//           <li className="nav-item"><Link className="nav-link" to="/contact">Contacto</Link></li>
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default Header;


import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './header.css';

const Header = () => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY > lastScrollY) {
        setShow(false); 
      } else {
        setShow(true);  
      }
      setLastScrollY(window.scrollY); 
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <nav className={`navbar ${show ? '' : 'hidden'}`}>
      <div 
        className="logo" 
        style={{backgroundImage: `url('/logoAT2.png')`}}
        onClick={handleLogoClick}
      ></div>
      <div className="menu-icon" onClick={toggleMenu}>
        <div className={`hamburger ${menuOpen ? 'open' : ''}`}></div>
      </div>
      <ul className={`nav-list ${menuOpen ? 'active' : ''}`}>
        <li className="nav-item"><Link className="nav-link" to="/" onClick={() => setMenuOpen(false)}>Inicio</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/about" onClick={() => setMenuOpen(false)}>Sobre Nosotros</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/services" onClick={() => setMenuOpen(false)}>Servicios</Link></li>
        <li className="nav-item"><Link className="nav-link" to="/contact" onClick={() => setMenuOpen(false)}>Contacto</Link></li>
      </ul>
    </nav>
  );
};

export default Header;