import { initPlasmicLoader } from "@plasmicapp/loader-react";
import WhatsAppButton from "./components/whatssAppButton/whatsAppButton"; // Ajusta la ruta según tu estructura de archivos

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: "w7M3NqL7jAg27d3osvwzbb",
      token: "q8y7EDPM8MpG63mGYkdeyYoF1ceXJriUfxZGek2VpXlzFEm8mtBbgFnOa9TdCFMgWS2u4zejJ0LnTZjXEbw"
    }
  ],
  // Registra tu componente personalizado
  components: [
    {
      name: "WhatsAppButton",
      component: WhatsAppButton
    }
  ]
});