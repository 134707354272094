import React from 'react';
import { PlasmicComponent } from '@plasmicapp/loader-react';


const About = () =>{
    return (
      <div>
         <PlasmicComponent component="About" />
      </div>
      //   <div className="about">
      //   <h1>Quiénes Somos</h1>
      //   <p>Somos una empresa dedicada a la administración eficiente de condominios.</p>
      // </div>
    )
}

export default About