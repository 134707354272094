// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { PlasmicRootProvider } from '@plasmicapp/loader-react';
// import { PlasmicComponent } from '@plasmicapp/loader-react';
// import Header from './components/header';
// import Footer from './components/footer';
// import Home from './pages/home';
// import About from './pages/about';
// import Contact from './pages/contact';
// import Services from './pages/services';
// import Layout from './components/layout';
// import WhatsAppButton from './components/whatssAppButton/whatsAppButton';

// // Importa la configuración de Plasmic
// import { PLASMIC } from './plasmic-init';

// function App() {
//   return (
//     <PlasmicRootProvider loader={PLASMIC}>
//       <Router>
//         <Routes>
//           <Route path="/" element={<Layout />}>
//             <Route index element={<Home />} />
//             <Route path="about" element={<About />} />
//             <Route path="contact" element={<Contact />} />
//             <Route path="services" element={<Services />} />
//           </Route>
//         </Routes>
//       </Router>
//     </PlasmicRootProvider>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PlasmicRootProvider } from '@plasmicapp/loader-react';
import Layout from './components/layout/layout';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Services from './pages/services';
import { PLASMIC } from './plasmic-init';
import WhatsAppButton from './components/whatssAppButton/whatsAppButton'; // Asegúrate de importar tu componente

function App() {
  return (
    <PlasmicRootProvider loader={PLASMIC} components={{WhatsAppButton}}> {/* Añade tu componente aquí */}
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="services" element={<Services />} />
          </Route>
        </Routes>
      </Router>
    </PlasmicRootProvider>
  );
}

export default App;