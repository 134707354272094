// import React from 'react';
// import { Outlet } from 'react-router-dom';
// import { PlasmicComponent } from '@plasmicapp/loader-react';
// import WhatsAppButton from './whatssAppButton/whatsAppButton';

// const Layout = () => {
//   return (
//     <div>
//       <PlasmicComponent component="Header" /> {/* Header permanece fijo */}
//       <Outlet /> {/* Este es el lugar donde se renderizarán las páginas específicas */}
//       <PlasmicComponent component="Footer-main" /> 
//       <WhatsAppButton />
//     </div>
//   );
// }

// export default Layout;

import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../header/header';
import { PlasmicComponent } from '@plasmicapp/loader-react';
import WhatsAppButton from '../whatssAppButton/whatsAppButton';
import './layout.css';

const Layout = () => {
  return (
    <div>
    <Header />
    <div className="outlet-container"> {/* Aplica la clase aquí */}
      <Outlet />
    </div>
    <PlasmicComponent component="Footer-main" />
    <WhatsAppButton />
  </div>
  );
};

export default Layout;