import React from 'react';
import { PlasmicComponent } from '@plasmicapp/loader-react';

const Home = () =>{
    return (
    //     <div className="home">
    //     <h1>Bienvenidos a AT-Administra</h1>
    //     <p>Ofrecemos servicios profesionales de administración para su condominio.</p>
    //   </div>

    <PlasmicComponent component="Home" />
    
    // <PlasmicComponent component="Home" componentProps={{
    //     title: "Bienvenido a AT-Administra",
    //     description: "Ofrecemos servicios profesionales de administración para su condominio."
    //   }} />
    )
}

export default Home