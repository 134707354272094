import React, { useState, useEffect } from 'react';
import { PlasmicComponent } from '@plasmicapp/loader-react';
import emailjs from '@emailjs/browser';
import './contact.css';

const ContactPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      limitRate: {
        throttle: 5000,
      },
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: name,
          email: email,
          phone: phone,
          message: message,
          to_name: "Administrador" // Puedes cambiar esto al nombre que prefieras
        }
      );
      setStatus('success');
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
    }
  };

  return (
    <div className="contact-page">
      <PlasmicComponent component="Contact" />
      
      <div className="contact-form-container">
        {/* <h2 className="form-title">Contáctanos</h2> */}
        <p className="form-description">Envíanos un mensaje y te responderemos pronto.</p>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <div className="button-container">
            {status === 'sending' ? (
              <div className="spinner"></div> 
            ) : (
              <button type="submit" disabled={status === 'sending'} className="submit-button">
                Enviar Mensaje
              </button>
            )}
          </div>
        </form>
        {status === 'success' && (
          <p className="status-message success">Mensaje enviado con éxito. Gracias por contactarnos.</p>
        )}
        {status === 'error' && (
          <p className="status-message error">Hubo un error al enviar el mensaje. Por favor, intenta de nuevo.</p>
        )}
      </div>
    </div>
  );
};

export default ContactPage;
